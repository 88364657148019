import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class RoadmapPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
      activeItem: '',
    }
    this.handleClick = this.handleClick.bind(this)
    this.roadmapComponent = this.roadmapComponent.bind(this)
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  handleClick(dateitem) {
    var newState = {
      ...this.state,
      activeItem: dateitem,
    }
    console.log(dateitem)

    this.setState(newState)
  }
  roadmapComponent(mobile) {
    if (mobile) {
      switch (this.state.activeItem) {
        case 'sep2021':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">September 2021 -</span>
                  The EdgeForecaster team were working on another project
                  developing time-series based machine learning models. Applying
                  the models to financial market data, the accuracy helped them
                  realise the potential as a securities forecasting product and
                  EdgeForecaster was born.
                </div>
              </Grid>
            </Grid>
          )
        case 'dec2021':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">December 2021 -</span>
                  First prototype version of EdgeForecaster was created and
                  tested across multiple securities and market segments,
                  averaging 83.2% accuracy for its price forecasts.{' '}
                </div>
              </Grid>
            </Grid>
          )
        case 'jan2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">January 2022 -</span>
                  Beta release of the forecasting app launched. Tested in highly
                  volatile global markets at the start of 2022. Accuracy
                  maintained above 75% across multiple market securities.
                  Improvements made to data processing and Edge Search facility.
                </div>
              </Grid>
            </Grid>
          )
        case 'feb2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">February 2022 -</span>
                  Lifetime Subscription offer released. Available for the first
                  5000 subscribers who sign up before the official release of
                  EdgeForecaster. Lifetime subscribers will have full access to
                  EdgeForecaster for the life of the product and be able to
                  contribute to the evolution of the final features offered
                  before release.{' '}
                </div>
              </Grid>
            </Grid>
          )
        case 'mar2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">March 2022 -</span>
                  Mobile App released on Apple App Store and Google Play.
                  Realtime data, price forecasts and quantitative statistics
                  delivered directly to your mobile phone. Notifications
                  configurable to price levels, changes in forecast and
                  indicator levels{' '}
                </div>
              </Grid>
            </Grid>
          )
        case 'apr2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">April 2022 -</span>
                  Official release of EdgeForecaster for subscribers. Three tier
                  subscription model for all levels of traders and investors,
                  tailored to deliver the market edge needed to produce
                  consistent positive returns.
                </div>
              </Grid>
            </Grid>
          )
        case 'jun2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">June 2021 -</span>
                  Automated trading release. Subscribers can use a range of
                  tested EdgeForecaster strategies or customise their own to
                  deploy through the automated trading system. Notifications are
                  sent for trading signals where the subscriber wishes to
                  maintain manual execution but can also be set for automatic
                  execution.
                </div>
              </Grid>
            </Grid>
          )
        default:
          return (
            <Grid container spacing={0}>
              <Grid item xs={4}></Grid>
              <Grid item xs={8} />
            </Grid>
          )
      }
    } else {
      switch (this.state.activeItem) {
        case 'sep2021':
          return (
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">September 2021 -</span>
                  The EdgeForecaster team were working on another project
                  developing time-series based machine learning models. Applying
                  the models to financial market data, the accuracy helped them
                  realise the potential as a securities forecasting product and
                  EdgeForecaster was born.
                </div>
              </Grid>
              <Grid item xs={7} />
            </Grid>
          )
        case 'dec2021':
          return (
            <Grid container spacing={0}>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">December 2021 -</span>
                  First prototype version of EdgeForecaster was created and
                  tested across multiple securities and market segments,
                  averaging 83.2% accuracy for its price forecasts.{' '}
                </div>
              </Grid>
              <Grid item xs={6} />
            </Grid>
          )
        case 'jan2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">January 2022 -</span>
                  Beta release of the forecasting app launched. Tested in highly
                  volatile global markets at the start of 2022. Accuracy
                  maintained above 75% across multiple market securities.
                  Improvements made to data processing and Edge Search facility.
                </div>
              </Grid>
              <Grid item xs={5} />
            </Grid>
          )
        case 'feb2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">February 2022 -</span>
                  Lifetime Subscription offer released. Available for the first
                  5000 subscribers who sign up before the official release of
                  EdgeForecaster. Lifetime subscribers will have full access to
                  EdgeForecaster for the life of the product and be able to
                  contribute to the evolution of the final features offered
                  before release.{' '}
                </div>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          )
        case 'mar2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={5} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">March 2022 -</span>
                  Mobile App released on Apple App Store and Google Play.
                  Realtime data, price forecasts and quantitative statistics
                  delivered directly to your mobile phone. Notifications
                  configurable to price levels, changes in forecast and
                  indicator levels{' '}
                </div>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          )
        case 'apr2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={6} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">April 2022 -</span>
                  Official release of EdgeForecaster for subscribers. Three tier
                  subscription model for all levels of traders and investors,
                  tailored to deliver the market edge needed to produce
                  consistent positive returns.
                </div>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )
        case 'jun2022':
          return (
            <Grid container spacing={0}>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <div className="roadmapcontent">
                  <span className="roadmaphighlight">June 2021 -</span>
                  Automated trading release. Subscribers can use a range of
                  tested EdgeForecaster strategies or customise their own to
                  deploy through the automated trading system. Notifications are
                  sent for trading signals where the subscriber wishes to
                  maintain manual execution but can also be set for automatic
                  execution.
                </div>
              </Grid>
            </Grid>
          )
        default:
          return (
            <Grid container spacing={0}>
              <Grid item xs={4}></Grid>
              <Grid item xs={8} />
            </Grid>
          )
      }
    }
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Roadmap'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var textcol = 8
    var spacercol = 2
    if (isMobile) {
      textcol = 12
      spacercol = 0
    }

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={spacercol} />
                  <Grid item xs={textcol}>
                    <span>
                      EdgeForecaster is in the final stages of development and
                      testing before the official release of our web-based
                      forecasting app. This release will be closely followed by
                      a mobile app available through the App Store and Google
                      Play. These options for user access gives EdgeForecaster
                      an initial platform from which to expand in the future.
                    </span>
                    <br />
                    <br />
                    <span>
                      Delivering additional features and extra value for our
                      subscribers becomes the overarching focus for our team.
                      Further visualisations and quantitative statistics,
                      additional markets and securities, additional timeframes
                      for intraday and short-term forecasts, trading and
                      investment strategies from some of the world’s best
                      traders, but most importantly, introducing automated
                      trading for multiple brokers.{' '}
                    </span>
                    <br />
                    <span style={{ color: '#4648ff', fontWeight: 'bold' }}>
                      Click on the month to read about the progress
                    </span>
                  </Grid>
                  <Grid item xs={spacercol} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div class="wrap">
                  <div class="links">
                    <div
                      class="roadmapdatepast"
                      onClick={() => {
                        this.handleClick('sep2021')
                      }}
                    >
                      Sep 2021
                    </div>
                    <div
                      class="roadmapdatepast"
                      onClick={() => {
                        this.handleClick('dec2021')
                      }}
                    >
                      Dec 2021
                    </div>
                    <div
                      class="roadmapdatepast"
                      onClick={() => {
                        this.handleClick('jan2022')
                      }}
                    >
                      Jan 2022
                    </div>
                    <div
                      class="roadmapdatecont"
                      onClick={() => {
                        this.handleClick('feb2022')
                      }}
                    >
                      Feb 2022
                    </div>
                    <div
                      class="roadmapdatecont"
                      onClick={() => {
                        this.handleClick('mar2022')
                      }}
                    >
                      Mar 2022
                    </div>
                    <div
                      class="roadmapdatecont"
                      onClick={() => {
                        this.handleClick('apr2022')
                      }}
                    >
                      Apr 2022
                    </div>
                    <div
                      class="roadmapdatecont"
                      onClick={() => {
                        this.handleClick('jun2022')
                      }}
                    >
                      Jun 2022
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {this.roadmapComponent(isMobile)}
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(RoadmapPage)
export const RoadmapPageQuery = graphql`
  query RoadmapPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
